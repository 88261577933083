<template lang="pug">
#product-search-result
  .search-heading(:class="{'alert-success': search_modify_alert}")
    //- Breadcrumb trail and title.
    span(v-if="header_search_term") {{$t(`search.verbiage.search_for`)}} "{{header_search_term}}" {{$t(`search.verbiage.gave`)}} {{product_search_results? product_search_results.length : 0}} {{$t(`search.verbiage.results`)}}.
    span(v-else-if="category_ids && category_ids.length > 0") {{$t(`search.verbiage.search_for`)}} "{{translateText(categoryFor(category_ids[0]))}}" {{$t(`search.verbiage.gave`)}} {{product_search_results ? product_search_results.length : 0}} {{$t(`search.verbiage.results`)}}.
    span(v-else-if="(brand_ids && brand_ids.length > 0) || search_model") {{ $t('search.verbiage.search_for') }}
      | {{(brand_ids && brand_ids.length > 0)? (' ' + $t('global.label.brand') + ': '):' '}}
      span(v-for="(brand_id, index) in brand_ids" :key="'brand_'+brand_id") {{(index!=0?', ':' ') + translateText(brandFor(brand_id)) + ' ' }}
      | {{(brand_ids && brand_ids.length > 0)? '':''}}
      span(v-if="search_model") {{ $t('product_inquiry.model') }}: {{ search_model  }} 
      | {{ $t('search.verbiage.gave') }} {{product_search_results ? product_search_results.length : 0}} {{ $t('search.verbiage.results') }} 
    span(v-else) You have cleared your search and all products are showing. 
      span(v-if="refine_brands.length == 0 && refine_models.length == 0 && refine_filter_options.length == 0 && !refine_term") Please use the refine section below to search your product. 
    span(v-if="refine_brands.length || (isCategoryMoreRefine && refine_categories.length) || refine_models.length || refine_filter_options.length || refine_term") &nbsp;&nbsp; {{ $t('global.label.refine') }}"
      span(v-if="refine_term") {{refine_term}}
      span(v-if="refine_brands.length") {{refine_term? ', '+getAllRefineBrandName: getAllRefineBrandName}}
      <span v-if="isCategoryMoreRefine && getAllRefineCategories.length">{{ getAllRefineCategories }}</span>
      span(v-if="refine_models.length") {{(getAllRefineBrandName || refine_term) ? ', '+ getAllRefineModels : getAllRefineModels}}
      span(v-if="refine_filter_options.length") {{ refiningByFilterOptionLabel }}
      span " {{ $t('global.label.reduced_results') }} {{refined_search_results.length}}.
  .search-body
    b-sidebar#sidebar-right.sidebar-filter(no-header right shadow backdrop)
      .selected-filter-list.px-3.py-2 
        .filter-icon.mb-2(v-b-toggle.sidebar-right)
          b-img.pr-2(v-if="isChinese" src="@/assets/images/filter_zh.png")
          b-img.pr-2(v-else src="@/assets/images/filter.svg")    
        .filter-heading {{ $t('search.label.you_choose') }}:
        span.filter-item(v-if="header_search_term")
          span.value {{header_search_term}}
          span.icon-close(@click="header_search_term=''")
            b-icon-x
        
        span.filter-item(v-for="brand_id in brand_ids" :key="'brand_head'+brand_id") Brand: 
          span.value {{brandFor(brand_id).name_en}}
          span.icon-close
            b-icon-x

        span.filter-item(v-if="search_model") Model:  
          span.value {{search_model}}
          span.icon-close
            b-icon-x

        span.filter-item(v-for="brand_id in refine_brands" :key="'name_en'+brand_id") Brand: 
          span.value {{translateText(brandFor(brand_id))}}
          span.icon-close(@click="removeBrand(brand_id)")
            b-icon-x

        
        span.filter-item(v-for="model in refine_models" :key="'model'+model" ) Model:  
          span.value {{model}}
          span.icon-close(@click="removeModel(model)")
            b-icon-x

        span.filter-item(v-for="category in refine_categories" :key="'category-'+category" ) {{getCategoryType(category)}}: 
          span.value {{translateText(categoryFor(category))}} 
          span.icon-close(@click="removeCategory(category)")
            b-icon-x
        span.filter-item(v-for="categoryId in refine_filter_options" :key="'categoryId-'+categoryId" ) {{getOptionCategories(categoryId).name}}: 
          span.value {{getOptionCategories(categoryId).value}} 
          span.icon-close(@click="removeOptionCategory(categoryId)")
            b-icon-x

      .left-body
        .filter-block
          .search-filter.border-bottom 
            .search-title {{ $t('search.verbiage.refine_search') }}
            //- b-form-input(:placeholder="$t('search.verbiage.search_within')" v-model="refine_term" debounce="500" @update="refine" )
            b-input-group.search-input
              b-form-input(:placeholder="$t('search.verbiage.search_within')" v-model="refine_term" debounce="500" @update="refine")
              template#append
                b-input-group-text
                  b-icon-search
          .filter-list-block(v-if="product_search_total > 0")
            .accordion.oem-block(role="tablist")
              b-card.mb-1(no-body)
                b-card-header.p-0(header-tag="header" role="tab")
                  .accordion-title(block v-b-toggle.accordion-1 :style="primaryColor") {{ $t('search.label.brand_filtering') }}
                    b-img.ml-2.accordion-arrow(src="@/assets/images/open.svg")
                b-collapse#accordion-1(visible accordion="my-accordion1" role="tabpanel")
                  b-card-body
                    .top-search-group
                      .input-search
                        span
                          b-icon-search
                        b-form-input(v-model="brand_search" :placeholder=`$t('search.verbiage.begin_typing')` debounce="500")
                    //- b-form-checkbox-group(size="sm" v-model="refine_brands" :options="refine_brands_options" @change="refine" stacked)
                    
                    b-form-checkbox-group#brand-check-group(v-model="refine_brands" @change="refine")
                      b-form-checkbox.d-block(v-for="(brand, key) in refine_brands_options" :key="'bid'+key" :value="brand.value")
                        span(:class="{'font-weight-bold': brand.is_popular}") {{brand.text}} 
                          span.font-weight-bold(:style="primaryColor") {{getBrandCountById(brand.value)?' ('+ getBrandCountById(brand.value) +')': ''}}

                    .pagination
                      b-link(href="#" @click="adjustShowingBrands(10)" v-if="showing_brands < brands_in_scope.length") {{$t('search.label.show_more')}}
                      span.pl-1.pr-1(v-if="showing_brands < brands_in_scope.length")  | 
                      b-link(href="#" @click="adjustShowingBrands('all')" v-if="showing_brands < brands_in_scope.length") {{$t('search.label.show_all')}}
                      span.pl-1.pr-1(v-if="showing_brands < brands_in_scope.length && showing_brands > 5")  | 
                      b-link(href="#" @click="adjustShowingBrands('less')" v-if="showing_brands > 5") {{$t('search.label.show_less')}}  

            .accordion.model-block(role="tablist")
              b-card.mb-1(no-body)
                b-card-header.p-0(header-tag="header" role="tab")
                  .accordion-title(block v-b-toggle.accordion-2 :style="primaryColor") {{ $t('search.label.model_filtering') }}
                    b-img.ml-2.accordion-arrow(src="@/assets/images/open.svg")
                b-collapse#accordion-2(visible accordion="my-accordion2" role="tabpanel")
                  b-card-body
                    .top-search-group
                      .input-search
                        span
                          b-icon-search
                        b-form-input(v-model="model_search" :placeholder=`$t('search.verbiage.begin_typing_model')` debounce="500")
                    b-form-checkbox-group(size="sm" v-model="refine_models" :options="refine_models_options" @change="refine" stacked)
                    .pagination
                      b-link(href="#" @click="adjustShowingModels(10)" v-if="showing_models < models_in_results.length") {{$t('search.label.show_more')}}
                      span.pl-1.pr-1(v-if="showing_models < models_in_results.length")  | 
                      b-link(href="#" @click="adjustShowingModels('all')" v-if="showing_models < models_in_results.length") {{$t('search.label.show_all')}}
                      span.pl-1.pr-1(v-if="showing_models > 5 && showing_models < models_in_results.length")  | 
                      b-link(href="#" @click="adjustShowingModels('less')" v-if="showing_models > 5") {{$t('search.label.show_less')}}   

            .accordion.category-block(role="tablist")
              b-card.mb-1(no-body)
                b-card-header.p-0(header-tag="header" role="tab")
                  .accordion-title(block v-b-toggle.accordion-3 :style="primaryColor") {{ $t('search.label.category_filtering') }}
                    b-img.ml-2.accordion-arrow(src="@/assets/images/open.svg")
                b-collapse#accordion-3(visible accordion="my-accordion3" role="tabpanel")
                  b-card-body
                    .parent-category.border-bottom(v-for="(parentCategory, key) in accessory_categories" :key="'p'+parentCategory.text+key")
                      //- label.parent-category-label {{parentCategory.text}}
                      .parent-category-label.mb-2(v-if="getCheckedInfo(parentCategory)['checked'] != 0 && getCheckedInfo(parentCategory)['checked'] != getCheckedInfo(parentCategory)['total']")
                        b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(parentCategory, true, true)")
                        span {{parentCategory.text}} 
                      b-form-checkbox.parent-category-label(
                        v-else
                        v-model="refine_categories" 
                        @change="onCategoryChange(parentCategory, true, true)" size="sm" :value="parentCategory.id" :name="parentCategory.text" :id="'id-'+parentCategory.id") 
                        span {{parentCategory.text}} 
                      .category(v-for="(category, index) in parentCategory.nodes" :key="'pc-'+index")
                        .category-header
                          span.mini-accordion(v-b-toggle="'accordion-'+category.id")
                            b-icon-caret-down-fill( variant="primary" :style="primaryColor")
                          .remove-category.ml-3.mb-2(v-if="getCheckedInfo(category)['checked'] != 0 && getCheckedInfo(category)['checked'] != getCheckedInfo(category)['total']")
                            b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(category, true)")
                            span {{category.text}} 
                              span.text-primary.font-weight-bold {{getProductCountByCategory(category)?' ('+ getProductCountByCategory(category) +')': ''}}
                          b-form-checkbox.ml-3(v-else v-model="refine_categories" @change="onCategoryChange(category, true)" size="sm" :value="category.id" :name="category.text" :id="'id-'+category.id") 
                            span {{category.text}}  
                              span.text-primary.font-weight-bold {{getProductCountByCategory(category)?' ('+ getProductCountByCategory(category) +')': ''}}
                        b-collapse.ml-4(close :id="'accordion-'+category.id" :accordion="'my-accordion'+category.id" role="tabpanel")
                          .sub-category
                            .sub-category-item(v-for="(subCategory, index) in category.nodes" :key="'sc-'+index")
                              span.mini-accordion(v-b-toggle="'accordion-'+subCategory.id" v-if="subCategory['nodes'] && subCategory['nodes'].length")
                                b-icon-caret-down-fill( variant="primary" :style="primaryColor")
                              .remove-category.ml-3.mb-2(v-if="getCheckedInfo(subCategory)['checked'] != 0 && getCheckedInfo(subCategory)['checked'] != getCheckedInfo(subCategory)['total']")
                                b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(subCategory, true)")
                                span {{subCategory.text}} 
                                  span.text-primary.font-weight-bold {{getProductCountByCategory(subCategory)?' ('+ getProductCountByCategory(subCategory) +')': ''}}
                              b-form-checkbox.d-block(v-else :value="subCategory.id" :class="{'ml-3': subCategory['nodes'] && subCategory['nodes'].length}" @change="onCategoryChange(subCategory, true)" v-model="refine_categories" :name="category.text" :id="'id-'+subCategory.id")
                                span {{subCategory.text}}
                                  span.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(subCategory)?' ('+ getProductCountByCategory(subCategory) +')': ''}}
                              b-collapse.ml-4(close v-if="subCategory['nodes'] && subCategory['nodes'].length" :id="'accordion-'+subCategory.id" :accordion="'my-accordion'+subCategory.id" role="tabpanel")
                                .sub-category
                                  b-form-checkbox.d-block(v-for="(childCategory, key) in subCategory.nodes" :key="'subCategory'+key" :value="childCategory.id" @change="onCategoryChange(childCategory, false)" v-model="refine_categories" :name="category.text" :id="'id-'+childCategory.id")
                                    span {{childCategory.text}}
                                      span.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(childCategory)?' ('+ getProductCountByCategory(childCategory) +')': ''}}
                      .pagination.mb-2
                        b-link(href="#" @click="adjustShowingAccessories(5)" v-if="showing_accessories < accessories.length") {{$t('search.label.show_more')}}
                        span.pl-1.pr-1(v-if="showing_accessories < accessories.length")  | 
                        b-link(href="#" @click="adjustShowingAccessories('all')" v-if="showing_accessories < accessories.length") {{$t('search.label.show_all')}}
                        span.pl-1.pr-1( v-if="showing_accessories < accessories.length && showing_accessories > 5" )  | 
                        b-link(href="#" @click="adjustShowingAccessories('less')" v-if="showing_accessories > 5") {{$t('search.label.show_less')}}       

                    .parent-category.border-bottom.mt-2(v-for="(parentCategory, key) in part_categories" :key="'parentCategory-'+parentCategory.text+key" v-if="isMedtenCustomer")
                      //- label.parent-category-label {{parentCategory.text}}
                      .parent-category-label.mb-2(v-if="getCheckedInfo(parentCategory)['checked'] != 0 && getCheckedInfo(parentCategory)['checked'] != getCheckedInfo(parentCategory)['total']")
                        b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(parentCategory, true, true)")
                        span {{parentCategory.text}} 
                      b-form-checkbox.parent-category-label(
                        v-else
                        v-model="refine_categories" 
                        @change="onCategoryChange(parentCategory, true, true)" size="sm" :value="parentCategory.id" :name="parentCategory.text" :id="'id-'+parentCategory.id") 
                        span {{parentCategory.text}} 
                      .category(v-for="(category, index) in parentCategory.nodes" :key="'pci'+index")
                        .category-header
                          span.mini-accordion(v-b-toggle="'accordion-'+category.id")
                            b-icon-caret-down-fill( variant="primary" :style="primaryColor")
                          .remove-category.ml-3.mb-2(v-if="getCheckedInfo(category)['checked'] != 0 && getCheckedInfo(category)['checked'] != getCheckedInfo(category)['total']")
                            b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(category, true)")
                            span {{category.text}} 
                              span.text-primary.font-weight-bold {{getProductCountByCategory(category)?' ('+ getProductCountByCategory(category) +')': ''}}
                          b-form-checkbox.ml-3(v-else v-model="refine_categories" @change="onCategoryChange(category, true)" size="sm" :value="category.id" :name="category.text" :id="'id-'+category.id") 
                            span {{category.text}}
                              span.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(category)?' ('+ getProductCountByCategory(category) +')': ''}}
                              //- {{category.text}} {{category.nodes && category.nodes.length?'('+category.nodes.length+')':''}}
                        b-collapse.ml-4(close :id="'accordion-'+category.id" :accordion="'my-accordion'+category.id" role="tabpanel")
                          .sub-category
                            .sub-category-item(v-for="(subCategory, index) in category.nodes" :key="'sci'+index")
                              span.mini-accordion(v-b-toggle="'accordion-'+subCategory.id" v-if="subCategory['nodes'] && subCategory['nodes'].length")
                                b-icon-caret-down-fill( variant="primary" :style="primaryColor")
                              .remove-category.ml-3.mb-2(v-if="getCheckedInfo(subCategory)['checked'] != 0 && getCheckedInfo(subCategory)['checked'] != getCheckedInfo(subCategory)['total']")
                                b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(subCategory, true)")
                                span {{subCategory.text}} 
                                  span.text-primary.font-weight-bold {{getProductCountByCategory(subCategory)?' ('+ getProductCountByCategory(subCategory) +')': ''}}
                              b-form-checkbox.d-block(:value="subCategory.id" :class="{'ml-3': subCategory['nodes'] && subCategory['nodes'].length}" @change="onCategoryChange(subCategory, true)" v-model="refine_categories" :name="category.text" :id="'id-'+subCategory.id")
                                span {{subCategory.text}}
                                  span.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(subCategory)?' ('+ getProductCountByCategory(subCategory) +')': ''}}
                              b-collapse.ml-4(close v-if="subCategory['nodes'] && subCategory['nodes'].length" :id="'accordion-'+subCategory.id" :accordion="'my-accordion'+subCategory.id" role="tabpanel")
                                .sub-category
                                  b-form-checkbox.d-block(v-for="(childCategory, key) in subCategory.nodes" :key="'childCategory'+key" :value="childCategory.id" @change="onCategoryChange(childCategory, false)" v-model="refine_categories" :name="category.text" :id="'id-'+childCategory.id")
                                    span {{childCategory.text}}
                                      span.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(childCategory)?' ('+ getProductCountByCategory(childCategory) +')': ''}}
                      .pagination.mb-2
                        b-link(href="#" @click="adjustShowingParts(5)" v-if="showing_parts < parts.length") {{$t('search.label.show_more')}}
                        span.pl-1.pr-1(v-if="showing_parts < parts.length")  | 
                        b-link(href="#" @click="adjustShowingParts('all')" v-if="showing_parts < parts.length") {{$t('search.label.show_all')}}
                        span.pl-1.pr-1( v-if="showing_parts < parts.length && showing_parts > 5" )  | 
                        b-link(href="#" @click="adjustShowingParts('less')" v-if="showing_parts > 5") {{$t('search.label.show_less')}}     

            .accordion.category-block(role="tablist" v-if="filter_option_categories.length > 0")
              b-card.mb-1(no-body)
                b-card-header.p-0(header-tag="header" role="tab")
                  .accordion-title(block v-b-toggle.accordion-4 :style="primaryColor") {{ $t('search.label.additional_filtering') }} 
                    b-img.ml-2.accordion-arrow(src="@/assets/images/open.svg")
                b-collapse#accordion-4(visible accordion="my-accordion4" role="tabpanel")
                  b-card-body
                    .accordion.additional-filter-options(role="tablist" v-for="foc, idx in filter_option_categories" :key="'fci'+idx")
                      b-card.mb-1(no-body)
                        b-card-header.p-0(header-tag="header" role="tab")
                          .accordion-title(block v-b-toggle="`accordion-4-`+idx" :style="primaryColor") {{ translateText(foc) }}
                            b-img.ml-2.accordion-arrow(src="@/assets/images/open.svg")
                        b-collapse(visible :accordion="'my-accordion4-'+idx" :id="'accordion-4-'+idx" role="tabpanel")
                          b-card-body
                            b-container.mb-2( fluid v-for="filter, idx2 in foc.filters" :key="'filter'+idx2").p-0
                              .sub-header {{ translateText(filter) }}
                              .sub-item-list(v-for="fopt, idx3 in filter.options" :key="'fopt'+idx3")
                                b-form-checkbox(size="sm" v-model="refine_filter_options" :value="fopt.value" :name="filter.name" :id="'id'+fopt.value") {{ fopt.text }}
                            
          .filter-list-block(v-else)
            b-container.p-0.mt-2( fluid v-if="product_search_total === 0") {{ $t('global.verbiage.product_not_found') }}
          .mob-filter-footer 
            b-button.add-to-cart-btn.text-light.mr-3(variant="primary" size="sm" @click="clearSearch()")
              span {{ $t('search.label.reset') }}
            b-button.add-to-cart-btn.text-light.mr-1(variant="primary" size="sm" v-b-toggle.sidebar-right)
              span {{ $t('search.label.show') }}
    //- L/R split view
    b-row(no-gutters)
      //- left
      b-col.left-body.d-none.d-lg-block(cols="12" lg="3")
        .filter-block
          .search-filter(:style="secondaryBgColorWithoutBorder")
            .search-title {{ $t('search.verbiage.refine_search') }}
            //- b-form-input(:placeholder="$t('search.verbiage.search_within')" v-model="refine_term" debounce="500" @update="refine" )
            b-input-group.search-input
              b-form-input(:placeholder="$t('search.verbiage.search_within')" v-model="refine_term" debounce="500" @update="refine" :style="secondaryBgColorWithoutBorder")
              template#append
                b-input-group-text
                  b-icon-search(:style="secondaryColor")
          .filter-list-block(:style="secondaryBorder" v-if="product_search_total > 0")
            .accordion.oem-block(role="tablist")
              CompatibilityBrandFilter(v-if="product_search_results" v-model="refine_brands" @input="refine" :primaryColor="primaryColor" :product_search_results="product_search_results" :brands="brands")

            .accordion.model-block(role="tablist" :class="{ disabledModel: refine_brands.length <= 0 }")
              b-card.mb-1(no-body ) 
                b-card-header.p-0(header-tag="header" role="tab")
                  .accordion-title(block v-b-toggle.accordion-2 :style="primaryColor") {{ $t('search.label.model_filtering') }} 
                    b-img.ml-2.accordion-arrow(src="@/assets/images/open.svg")
                b-collapse#accordion-2(visible accordion="my-accordion2" role="tabpanel")
                  b-card-body
                    .top-search-group
                      .input-search
                        span
                          b-icon-search
                        b-form-input(v-model="model_search" :placeholder=`$t('search.verbiage.begin_typing_model')` debounce="500" @update="refine")

                    b-form-checkbox-group(size="sm" v-model="refine_models" :options="refine_models_options" @change="refine" stacked)
                    .pagination
                      b-link(href="#" @click="adjustShowingModels(10)" v-if="showing_models < models_in_results.length") {{$t('search.label.show_more')}}
                      span.pl-1.pr-1(v-if="showing_models < models_in_results.length")  | 
                      b-link(href="#" @click="adjustShowingModels('all')" v-if="showing_models < models_in_results.length") {{$t('search.label.show_all')}}
                      span.pl-1.pr-1(v-if="showing_models > 5 && showing_models < models_in_results.length")  | 
                      b-link(href="#" @click="adjustShowingModels('less')" v-if="showing_models > 5") {{$t('search.label.show_less')}}   

            .accordion.category-block(role="tablist")
              b-card.mb-1(no-body)
                b-card-header.p-0(header-tag="header" role="tab")
                  .accordion-title(block v-b-toggle.accordion-3 :style="primaryColor") {{ $t('search.label.category_filtering') }} 
                    b-img.ml-2.accordion-arrow(src="@/assets/images/open.svg")
                b-collapse#accordion-3(close accordion="my-accordion3" role="tabpanel")
                  b-card-body
                    .parent-category.border-bottom(v-for="(parentCategory, key) in accessory_categories" v-if="!isCategorySearch() || parentCategory.checkedInfo.checked != 0 || parentCategory.checkedInfo.selfChecked" :key="'pci'+parentCategory.text+key")
                      //- label.parent-category-label {{parentCategory.text}}
                      .parent-category-label.mb-2(v-if="getCheckedInfo(parentCategory)['checked'] != 0 && getCheckedInfo(parentCategory)['checked'] != getCheckedInfo(parentCategory)['total']")
                        b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(parentCategory, true, true)")
                        span {{parentCategory.text}} 
                      b-form-checkbox.parent-category-label(
                        v-else
                        v-model="refine_categories" 
                        @change="onCategoryChange(parentCategory, true, true)" size="sm" :value="parentCategory.id" :name="parentCategory.text" :id="'id-'+parentCategory.id") 
                        span {{parentCategory.text}} 
                        //- span.text-primary.font-weight-bold {{getProductCountByCategory(parentCategory)?' ('+ getProductCountByCategory(parentCategory) +')': ''}} {{getCheckedInfo(parentCategory)}}
                      
                      .category(v-for="(category, index) in parentCategory.nodes" v-if="!isCategorySearch() || (category.checkedInfo.checked != 0 || category.checkedInfo.selfChecked)" :key="'parentCategory'+index")
                        .category-header
                          span.mini-accordion(v-b-toggle="'accordion-'+category.id")
                            b-icon-caret-down-fill( variant="primary" :style="primaryColor")
                          .remove-category.ml-3.mb-2(v-if="getCheckedInfo(category)['checked'] != 0 && getCheckedInfo(category)['checked'] != getCheckedInfo(category)['total']")
                            b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(category, true)")
                            span {{category.text}} 
                              span.text-primary.font-weight-bold {{getProductCountByCategory(category)?' ('+ getProductCountByCategory(category) +')': ''}}
                          b-form-checkbox.ml-3(v-else v-model="refine_categories" @change="onCategoryChange(category, true)" size="sm" :value="category.id" :name="category.text" :id="'id-'+category.id") 
                            span {{category.text}} 
                              span.text-primary.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(category)?' ('+ getProductCountByCategory(category) +')': ''}}
                        b-collapse.ml-4(:visible='isCategorySearch()' close :id="'accordion-'+category.id" :accordion="'my-accordion'+category.id" role="tabpanel")
                          .sub-category
                            .sub-category-item(v-for="(subCategory, index) in category.nodes" v-if="!isCategorySearch() || (subCategory.checkedInfo.checked != 0 || subCategory.checkedInfo.selfChecked)" :key="'subCategory'+index")
                              span.mini-accordion(v-b-toggle="'accordion-'+subCategory.id" v-if="subCategory['nodes'] && subCategory['nodes'].length")
                                b-icon-caret-down-fill( variant="primary" :style="primaryColor")
                              .remove-category.ml-3.mb-2(v-if="getCheckedInfo(subCategory)['checked'] != 0 && getCheckedInfo(subCategory)['checked'] != getCheckedInfo(subCategory)['total']")
                                b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(subCategory, true)")
                                span {{subCategory.text}} 
                                  span.text-primary.font-weight-bold {{getProductCountByCategory(subCategory)?' ('+ getProductCountByCategory(subCategory) +')': ''}}
                              b-form-checkbox.d-block(v-else :value="subCategory.id" :class="{'ml-3': subCategory['nodes'] && subCategory['nodes'].length}" @change="onCategoryChange(subCategory, true)" v-model="refine_categories" :name="category.text" :id="'id-'+subCategory.id")
                                span {{subCategory.text}} 
                                  span.text-primary.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(subCategory)?' ('+ getProductCountByCategory(subCategory) +')': ''}}
                              b-collapse.ml-4(:visible='isCategorySearch()' close v-if="subCategory['nodes'] && subCategory['nodes'].length" :id="'accordion-'+subCategory.id" :accordion="'my-accordion'+subCategory.id" role="tabpanel")
                                .sub-category
                                  b-form-checkbox.d-block(v-for="(childCategory, key) in subCategory.nodes" :key="'childCategory'+key" :value="childCategory.id" @change="onCategoryChange(childCategory, false)" v-model="refine_categories" :name="category.text" :id="'id-'+childCategory.id")
                                    span {{childCategory.text}} 
                                      span.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(childCategory)?' ('+ getProductCountByCategory(childCategory) +')': ''}}
                      .pagination.mb-2(v-if="!isCategorySearch()")
                        b-link(href="#" @click="adjustShowingAccessories(5)" v-if="showing_accessories < accessories.length") {{$t('search.label.show_more')}}
                        span.pl-1.pr-1(v-if="showing_accessories < accessories.length")  | 
                        b-link(href="#" @click="adjustShowingAccessories('all')" v-if="showing_accessories < accessories.length") {{$t('search.label.show_all')}}
                        span.pl-1.pr-1( v-if="showing_accessories < accessories.length && showing_accessories > 5" )  | 
                        b-link(href="#" @click="adjustShowingAccessories('less')" v-if="showing_accessories > 5") {{$t('search.label.show_less')}}        

                    .parent-category.border-bottom.mt-2(v-for="(parentCategory, key) in part_categories" :key="parentCategory.text+key" v-if="isMedtenCustomer && (!isCategorySearch() || parentCategory.checkedInfo.checked != 0 || parentCategory.checkedInfo.selfChecked)" )
                      //- label.parent-category-label {{parentCategory.text}}
                      .parent-category-label.mb-2(v-if="getCheckedInfo(parentCategory)['checked'] != 0 && getCheckedInfo(parentCategory)['checked'] != getCheckedInfo(parentCategory)['total']")
                        b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(parentCategory, true, true)")
                        span {{parentCategory.text}} 
                      b-form-checkbox.parent-category-label(
                        v-else
                        v-model="refine_categories" 
                        @change="onCategoryChange(parentCategory, true, true)" size="sm" :value="parentCategory.id" :name="parentCategory.text" :id="'id-'+parentCategory.id") 
                        span {{parentCategory.text}} 
                      .category(v-for="(category, index) in parentCategory.nodes" v-if="!isCategorySearch() || category.checkedInfo.checked != 0 || category.checkedInfo.selfChecked" :key="'pci'+index")
                        .category-header
                          span.mini-accordion(v-b-toggle="'accordion-'+category.id")
                            b-icon-caret-down-fill( variant="primary" :style="primaryColor")
                          .remove-category.ml-3.mb-2(v-if="getCheckedInfo(category)['checked'] != 0 && getCheckedInfo(category)['checked'] != getCheckedInfo(category)['total']")
                            b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(category, true)")
                            span {{category.text}} 
                              span.text-primary.font-weight-bold {{getProductCountByCategory(category)?' ('+ getProductCountByCategory(category) +')': ''}}
                          b-form-checkbox.ml-3(v-else v-model="refine_categories" @change="onCategoryChange(category, true)" size="sm" :value="category.id" :name="category.text" :id="'id-'+category.id")
                            span {{category.text}}
                              span.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(category)?' ('+ getProductCountByCategory(category) +')': ''}}
                        b-collapse.ml-4(close :visible='isCategorySearch()' :id="'accordion-'+category.id" :accordion="'my-accordion'+category.id" role="tabpanel")
                          .sub-category
                            .sub-category-item(v-for="(subCategory, index) in category.nodes" v-if="!isCategorySearch() || subCategory.checkedInfo.checked != 0 || subCategory.checkedInfo.selfChecked" :key="'sci'+index")
                              span.mini-accordion(v-b-toggle="'accordion-'+subCategory.id" v-if="subCategory['nodes'] && subCategory['nodes'].length")
                                b-icon-caret-down-fill( variant="primary" :style="primaryColor")
                              .remove-category.ml-3.mb-2(v-if="getCheckedInfo(subCategory)['checked'] != 0 && getCheckedInfo(subCategory)['checked'] != getCheckedInfo(subCategory)['total']")
                                b-icon-dash-square-fill.mr-2.text-primary(@click="onRemoveSubCategory(subCategory, true)")
                                span {{subCategory.text}} 
                                  span.text-primary.font-weight-bold {{getProductCountByCategory(subCategory)?' ('+ getProductCountByCategory(subCategory) +')': ''}}
                              b-form-checkbox.d-block(v-else :value="subCategory.id" :class="{'ml-3': subCategory['nodes'] && subCategory['nodes'].length}" @change="onCategoryChange(subCategory, true)" v-model="refine_categories" :name="category.text" :id="'id-'+subCategory.id")
                                span {{subCategory.text}}
                                  span.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(subCategory)?' ('+ getProductCountByCategory(subCategory) +')': ''}}
                              b-collapse.ml-4(close :visible='isCategorySearch()' v-if="subCategory['nodes'] && subCategory['nodes'].length" :id="'accordion-'+subCategory.id" :accordion="'my-accordion'+subCategory.id" role="tabpanel")
                                .sub-category
                                  b-form-checkbox.d-block(v-for="(childCategory, key) in subCategory.nodes" :key="'cci'+key" :value="childCategory.id" @change="onCategoryChange(childCategory, false)" v-model="refine_categories" :name="category.text" :id="'id-'+childCategory.id")
                                    span {{childCategory.text}}
                                      span.font-weight-bold(:style="primaryColor") {{getProductCountByCategory(childCategory)?' ('+ getProductCountByCategory(childCategory) +')': ''}}
                      .pagination.mb-2(v-if="!isCategorySearch()")
                        b-link(href="#" @click="adjustShowingParts(5)" v-if="showing_parts < parts.length") {{$t('search.label.show_more')}}
                        span.pl-1.pr-1(v-if="showing_parts < parts.length")  | 
                        b-link(href="#" @click="adjustShowingParts('all')" v-if="showing_parts < parts.length") {{$t('search.label.show_all')}}
                        span.pl-1.pr-1( v-if="showing_parts < parts.length && showing_parts > 5" )  | 
                        b-link(href="#" @click="adjustShowingParts('less')" v-if="showing_parts > 5") {{$t('search.label.show_less')}}    
            
            .accordion.category-block(role="tablist" v-if="filter_option_categories.length > 0")
              b-card.mb-1(no-body)
                b-card-header.p-0(header-tag="header" role="tab")
                  .accordion-title(block v-b-toggle.accordion-4 :style="primaryColor") {{ $t('search.label.additional_filtering') }}
                    b-img.ml-2.accordion-arrow(src="@/assets/images/open.svg")
                b-collapse#accordion-4(visible accordion="my-accordion4" role="tabpanel")
                  b-card-body
                    .accordion.additional-filter-options(role="tablist" v-for="(foc, idx) in filter_option_categories" :key="'foc'+idx")
                      b-card.mb-1(no-body)
                        b-card-header.p-0(header-tag="header" role="tab")
                          .accordion-title(block v-b-toggle="`accordion-4-`+idx" :style="primaryColor") {{ translateText(foc) }}
                            b-img.ml-2.accordion-arrow(src="@/assets/images/open.svg")
                        b-collapse(visible :accordion="'my-accordion4-'+idx" :id="'accordion-4-'+idx" role="tabpanel")
                          b-card-body
                            b-container.mb-2( fluid v-for="(filter, idx2) in foc.filters" :key="'filter'+idx2").p-0
                              .sub-header {{ translateText(filter) }}
                              .sub-item-list(v-for="(fopt, idx3) in filter.options" :key="'fopt'+idx3")
                                b-form-checkbox(size="sm" v-model="refine_filter_options" :value="fopt.value" :name="filter.name" :id="'id'+fopt.value") {{ fopt.text }} 
                            
       

          .filter-list-block(:style="secondaryBorder" v-else)
            b-container.p-0.mt-2( fluid v-if="product_search_total === 0") {{ $t('global.verbiage.product_not_found') }}
      
      //- right (main content area)
      b-col.right-body(cols="12" lg="9")
        .search-result-block
          //- .selected-filter-list.d-none.d-lg-block#selected-filter-list 
          //-   span.filter-item(v-if="header_search_term" :style="secondaryBgColor")
          //-     span.value {{header_search_term}}
          //-     span.icon-close(@click="header_search_term=''")
          //-       b-icon-x
            
          //-   span.filter-item(v-for="brand_id in brand_ids" :key="'brand_'+brand_id" :style="secondaryBgColor") Brand: 
          //-     span.value {{translateText(brandFor(brand_id))}}
          //-     span.icon-close
          //-       b-icon-x

          //-   span.filter-item(v-if="search_model" :style="secondaryBgColor") Model:  
          //-     span.value {{search_model}}
          //-     span.icon-close
          //-       b-icon-x

          //-   span.filter-item(v-for="brand_id in refine_brands" :key="'b-'+brand_id" :style="secondaryBgColor") Brand: 
          //-     span.value {{translateText(brandFor(brand_id))}}
          //-     span.icon-close(@click="removeBrand(brand_id)")
          //-       b-icon-x


          //-   span.filter-item(v-for="model in refine_models" :key="'model-'+model" :style="secondaryBgColor") Model:  
          //-     span.value {{model}}
          //-     span.icon-close(@click="removeModel(model)")
          //-       b-icon-x

          //-   span.filter-item(v-for="category in refine_categories" :key="'category'+category" :style="secondaryBgColor") {{getCategoryType(category)}}:  
          //-     span.value {{translateText(categoryFor(category))}} 
          //-     span.icon-close(@click="removeCategory(category)")
          //-       b-icon-x

          //-   span.filter-item(v-for="categoryId in refine_filter_options" :key="'categoryId'+categoryId" :style="secondaryBgColor") {{getOptionCategories(categoryId).name}}: 
          //-     span.value {{getOptionCategories(categoryId).value}} 
          //-     span.icon-close(@click="removeOptionCategory(categoryId)")
          //-       b-icon-x

          //-   span.filter-item.clear(@click="clearSearch()" v-if="header_search_term || brand_ids.length || search_model || refine_brands.length || refine_models.length || refine_categories.length") {{$t(`search.label.clear_all`)}}  
          //-     span.icon-close
          //-       b-icon-x

          //-   span.d-lg-none(v-b-toggle.sidebar-right)
          //-     b-img.pr-2(src="@/assets/images/filter.svg") 
          //- .mobile-filter.d-lg-none 
          //-   span.mobile-filter-wrap(v-b-toggle.sidebar-right)
          //-     span.filter-icon
          //-       b-img.pr-2(v-if="isChinese" src="@/assets/images/filter_zh.png")
          //-       b-img.pr-2(v-else src="@/assets/images/filter.svg")     

          //-     span.filter-item(v-if="header_search_term")
          //-       span.value {{header_search_term}}
              
          //-     span.filter-item(v-for="brand_id in brand_ids" :key="'brand_'+brand_id")
          //-       span.value {{translateText(brandFor(brand_id))}}

          //-     span.filter-item(v-if="search_model")
          //-       span.value {{search_model}}

          //-     span.filter-item(v-for="brand_id in refine_brands" :key="'b-'+brand_id")
          //-       span.value {{translateText(brandFor(brand_id))}}
              
          //-     span.filter-item(v-for="model in refine_models" :key="'m-'+model" )  
          //-       span.value {{model}}

          //-     span.filter-item(v-for="category in refine_categories" :key="'category-'+category" ) 
          //-       span.value {{translateText(categoryFor(category))}} 
              
          //- search result display
          .product-list.border-left#product-list
            //- Each result...
            SearchResultItem(v-for="(product, index) in viewable_product_search_results" :key="'p-'+index" :searchItem="product" :images="product_images" :class={'border-bottom': 1})
              
          //- pagination
          .pagination-content.border-bottom.border-left.border-top(v-if="viewable_product_search_results.length > 0")
              //- b-container.d-flex.justify-content-center( fluid )
              //- small.pt-2.mr-4 {{ from }} - {{ through }} of {{ refined_search_results.length  }}
              b-row 
                b-col.item-per-page.d-none.d-md-block(cols="12" md="3")
                  <label for="show-item">{{ $t('global.label.show') }}:</label>
                  //- v-model="per_page"
                  <b-form-select size="sm" :value="per_page" @change="changeParePage" :options="[5, 10, 20, 30, 40, 50]" id="show-item"></b-form-select>
                b-col.pagination-block(cols="12" md="6")
                  b-pagination(v-model="current_page" :total-rows="refined_search_results.length" :per-page="per_page" @change="page" size="sm" hide-ellipsis pills )
                b-col.page-info.d-none.d-md-block(cols="12" md="3")
                  small.pt-2.mr-4 {{ from }} - {{ through }} {{ $t('global.label.of') }}   {{ refined_search_results.length }}
  MissionPromiseStatement(:isSearch="true")
  Footer(:isSearch="true")
</template>

<script>
import utils from "@/mixins/utils";
import { VueOfflineStorage } from 'vue-offline';
import { mapActions, mapState, mapGetters } from 'pinia';
import { useApiUtilStore} from '../stores/apiutil-st';
import { useDbStore } from '../stores/db-st';
import { useProductSearchStore} from '../stores/product-search-st';
import FilterTree from '@/components/FilterTree'
import SearchResultItem from '@/components/SearchResultItem';
import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
import CompatibilityBrandFilter from '@/components/Filters/CompatibilityBrandFilter.vue';
const ACCESSORY_CATEGORY_ID = 1;
const PART_CATEGORY_ID = 2;
export default {
  name: 'Search',
  components: {
    FilterTree,
    SearchResultItem,
    MissionPromiseStatement,
    CompatibilityBrandFilter,
    Footer
  },
  mixins: [utils],
  props: {
    search_term: {
      required: false,
    }
  },

  data(){
    return {
      brand: null,
      isCategoryMoreRefine: false,
      //note: model == t_equipment
      current_page: 1,
      models: null, // null = *, otherwise array of those selected
      refined_search_results: [],
      per_page: 10,
      refine_term: '',
      refine_brands: [],
      showing_brands: 5,
      refine_categories: [],
      showing_categories: 5,
      refine_models: [],
      showing_models: 5,
      refine_filter_options: [],
      accessories: [],
      showing_accessories: 5,
      parts: [],
      showing_parts: 5,
      brand_ids: [],
      header_search_term: '',
      search_model: '',
      category_ids: [],
      isCleared: false,
      model_search: '',
      brand_search: '',
      search_modify_alert: false,
      calculatedAccessories: null,
      calculatedParts: null,
      compatibilityBrands: [],
    };
  },

  computed:{
    ...mapState( useDbStore, ['brands','categories','filter_options','lifecycles'] ),
    ...mapState( useProductSearchStore, ['brands_in_scope','models_in_results','product_images','product_search_model','product_search_term','product_search_results','product_search_offset','product_search_limit','product_search_total','search_criteria_provided','selected_brand_ids','selected_category_ids'] ),
    ...mapState( useApiUtilStore, ['busy']),
    ...mapGetters(useDbStore, ['isMedtenCustomer', 'primaryColor', 'secondaryColor', 'primaryBgColor', 'secondaryBgColor', 'primaryBgColorWithoutBorder', 'secondaryBgColorWithoutBorder', 'primaryBorder', 'secondaryBorder']),
    lifecycle_filters() { 
     return this.lifecycles?.map((item) => {
        return {
          isChecked : false,
          ...item
        }
     }).filter(item => item.id !== 3) || [] // filter out OTHERS lifecycle
    },
    refiningByFilterOptionLabel() {
      const filterOptionsRefineLabels = [];
      if (this.getAllRefineFilterOptions) {
        filterOptionsRefineLabels.push(this.getAllRefineFilterOptions)
      }
      if (this.getAllRefineBrandName || this.refine_term || this.getAllRefineModels) {
        return ', ' + filterOptionsRefineLabels.join(', ')
      } else {
        return filterOptionsRefineLabels.join(', ')
      }
    },
    from(){ return (this.current_page-1)*this.per_page + 1; },
    through(){ return Math.min(this.from + this.per_page - 1, this.refined_search_results.length); },
    accessory_category_id(){ return ACCESSORY_CATEGORY_ID; },
    accessory_categories(){
      if (this.isCategorySearch() && this.calculatedAccessories) {
        return this.calculatedAccessories;
      }
      let accessoryCategory = this.categories.find( c => c.id === ACCESSORY_CATEGORY_ID );
      let root = this.buildTree( accessoryCategory );
      this.accessories = root.nodes;
      if (!this.calculatedAccessories) {
        this.calculatedAccessories = this.calculateCheckedStatus([root]);
      }
      if (!this.isCategorySearch()) {
        root.nodes = root.nodes.slice(0, this.showing_accessories);
      }
      return [root];
    },
    part_categories(){
      if (this.isCategorySearch() && this.calculatedParts) {
        return this.calculatedParts;
      }
      let partCategory = this.categories.find( c => c.id === PART_CATEGORY_ID );
      let root = this.buildTree( partCategory );
      this.parts = root.nodes;
      if (!this.calculatedParts) {
        this.calculatedParts = this.calculateCheckedStatus([root]);
      }
      if (!this.isCategorySearch()) {
        root.nodes = root.nodes.slice(0, this.showing_parts);
      }
      return [root];
    },
    isShowingAllBrands() {
      if (this.showing_brands > 15) {
        return true
      }
    },
    refine_brands_options() {
      let filter_brands = this.sortRanks(this.brands_in_scope)
        .filter((item) => (item.name_en.toLowerCase().indexOf(this.brand_search.toLowerCase()) != -1));
      return [
        ...filter_brands.filter(item => item.is_popular), 
        ...filter_brands.filter(item => !item.is_popular)
        ].map((b) => {
          return { text: this.translateText(b), value: b.id, is_popular: b.is_popular };
        }).sort((a, b) => {
            let brandA = a.text.trim();
            let brandB = b.text.trim();
            return brandA.localeCompare(brandB);
        });
    },
    getAllRefineBrandName(){
      let name = "";
      let refine_brand_list = this.brands.filter(item => {return this.refine_brands.includes(item.id)});
      refine_brand_list.forEach((item, index) => {
        if(name == ''){
          name = item.name_en;
        }else{
          name = name+', '+item.name_en;
        }
      });
      return name;
    },
    getAllRefineModels(){
      return this.refine_models.join(', ');
    },
    getAllRefineCategories() {
      const matchedCategories = this.categories.filter(item => this.refine_categories.includes(item.id))
      return matchedCategories?.map((item) => item.name_en).join(', ') || ''

    },
    getAllRefineFilterOptions(){
      let options = this.filter_options.filter((item) => { 
        return this.refine_filter_options.includes(item.filter_option_id) 
      });
      const extendedLifecycleFilterOptions = this.getSelectedLifecycleFilterOption()
      const extendedFilters = []
      if (extendedLifecycleFilterOptions.length) {
        extendedLifecycleFilterOptions.forEach((foLifecycle) => {
          const matchedLifecycle = this.lifecycle_filters.find((lItem) => lItem.id === foLifecycle.lifecycleId)
          if (!extendedFilters.includes(this.translateText(matchedLifecycle))) {
            extendedFilters.push(this.translateText(matchedLifecycle))
          }
        })
      }
      let name = extendedFilters.join(', ');
      options.forEach(item =>{
        if(name == ""){
          name = item.option_en;
        }else{
          name = name+', '+item.option_en;
        }
      });
      return name;
    },
    refine_models_options(){
      let models = this.models_in_results;
      console.log(this.refine_brands)
      if(this.refine_brands && this.refine_brands.length){
        models = models.filter((m)=> {return this.refine_brands.includes(m.brand_id)});
      }
      models = models.sort( (m1, m2) => {
        if (m1.model < m2.model) return -1;
        if (m1.model > m2.model) return 1;
        return 0;
      }).filter((item)=> (item.model.toLowerCase().indexOf(this.model_search.toLowerCase())!=-1))
      .map( (m) => { 
        return m.model; 
      }).slice(0, this.showing_models); 
      
      models = [... new Set(models)]
      return models.map( (m) => {  return {text: m, value: m};  });
    },
    refine_categories_options(){
      return this.categories
        .sort( (m1, m2) => {
          if (m1 < m2) return -1;
          if (m1 > m2) return 1;
          return 0;
        })
        .map( (m) => { 
          return {text: m, value: m}; 
        })
        .slice(0, this.showing_categories); 
    },
    filter_option_categories(){
      //construct filter option arrays...
      let fo_categories = [];

      let avaliable_filter_option_ids = [];

      // list of filter option ids getting from search result.
      this.product_search_results.forEach(p => {
        if(p.filter_option_ids){
          let filterIds = p.filter_option_ids.split('|');
          avaliable_filter_option_ids = [...new Set([...avaliable_filter_option_ids , ...filterIds])]; 
        }
      });

      // get only applicable filter option based on the search
      let current_filter_options =  this.filter_options.filter((item) => {
        //- console.log(avaliable_filter_option_ids.indexOf(''+item.filter_option_id), item.filter_option_id);
        return (avaliable_filter_option_ids.indexOf(''+item.filter_option_id) != -1 && item.visible_in_catalog);
      })
      //- console.log('avaliable_filter_option_ids', avaliable_filter_option_ids, current_filter_options);
      current_filter_options.forEach(fo=>{
        let current_category = null;
        let current_filter = null;
        let category_exists = fo_categories.findIndex(foc => foc.id === fo.category_id)
        if(category_exists == -1){
          current_category = { name_en: fo.category_en, name_zh: fo.category_zh, id: fo.category_id, filters:[] };
          fo_categories.push(current_category);
        }else{
          current_category = fo_categories[category_exists];
        }
        
        let filter_exists = current_category.filters.findIndex(f => f.id === fo.filter_id);
        if(filter_exists == -1){
          current_filter = { id: fo.filter_id, name_en: fo.filter_en, name_zh: fo.filter_zh, options:[]};
          current_category.filters.push(current_filter);
        }else{
          current_filter = current_category.filters[filter_exists];
        }
        current_filter.options.push({ text: this.translateText(fo, 'option'), value: fo.filter_option_id});
      });

      //modifies the filters, including only those with more then one options.
      fo_categories.forEach( c => {
        c.filters = c.filters.filter( f => { return f.options.length > 1; });
      });

      //modifies the categories present, including only those with filters.
      fo_categories = fo_categories.filter( c => {
        return c.filters.length > 0;
      });
      // Add extended filter options for lifecycles
      fo_categories.forEach((item) => {
        item.filters.push({
          id: `lifecycle-${item.id}`,
          isExtendedFilterOption: true,
          "name_en": this.$t('product.label.lifecycle'),
          "name_zh": this.$t('product.label.lifecycle'),
          "options": this.lifecycle_filters.map((lifecycleItem) => ({
            text: this.translateText(lifecycleItem),
            value: `lifecycle-${item.id}-${lifecycleItem.id}`
          }))
        })
      })
      return fo_categories;
    },

    filter_option_categories_backup(){
      //construct filter option arrays...
      let fo_categories = [];

      let current_category = null;
      let current_filter = null;

      let avaliable_filter_option_ids = [];
      this.product_search_results.forEach(p => {
        if(p.filter_option_ids){
          let filterIds = p.filter_option_ids.split('|');
          avaliable_filter_option_ids = [...new Set([...avaliable_filter_option_ids , ...filterIds])]; 
        }
      });
      let current_filter_options =  this.filter_options.filter((item) => {
        return (avaliable_filter_option_ids.indexOf(''+item.filter_option_id) != -1);
      })
      //- console.log('current_filter_options', current_filter_options);
      current_filter_options.forEach(fo=>{
        let category_exists = fo_categories.find(foc => foc.id === fo.category_id)
        if(!category_exists){
          current_category = { name: fo.category_en, id: fo.category_id, filters:[] };
          fo_categories.push(current_category);
        }
        
        let filter_exists = current_category.filters.find(f => f.id === fo.filter_id);
        //- let filter_exists = current_category.filters.find(f => f.name === fo.filter_en);
        if(!filter_exists){
          current_filter = { id: fo.filter_id, name: fo.filter_en, options:[]};
          current_category.filters.push(current_filter);
        }
        current_filter.options.push({ text: this.translateText(fo, 'option'), value: fo.filter_option_id});
        
        //- let included = this.product_search_results.find(p => {
        //-   if(p.filter_option_ids){
        //-     let foids = p.filter_option_ids.split('|');
        //-     return foids.includes(''+fo.filter_option_id); //string comparison
        //-   }
        //-   return false;
        //- });
        //- if(included){
        //-   current_filter.options.push({ text: fo.option_en, value: fo.filter_option_id});
        //- }
      });

      //modifies the filters, including only those with options.
      fo_categories.forEach( c => {
        c.filters = c.filters.filter( f => { return f.options.length > 0; });
      });

      //modifies the categories present, including only those with filters.
      fo_categories = fo_categories.filter( c => {
        return c.filters.length > 0;
      });
      
      return fo_categories;
    },

    //- filter_option_categories_backup(){
    //-   //construct filter option arrays...
    //-   let filter_categories = [];
    //-   let avaliable_filter_option_ids = [];
    //-   this.product_search_results.forEach(p => {
    //-     if(p.filter_option_ids){
    //-       let filterIds = p.filter_option_ids.split('|');
    //-       avaliable_filter_option_ids = [...new Set([...avaliable_filter_option_ids , ...filterIds])]; 
    //-     }
    //-   });
    //-   let avaliable_filter_category = this.categories.filter(category => {
    //-     return (this.refine_categories.indexOf(category.id)!= -1);
    //-   });
    //-   //- console.log('avaliable_filter_option_ids', avaliable_filter_option_ids, avaliable_filter_category);

    //-   avaliable_filter_category.forEach(category => {
    //-     category['filters'] = [];
    //-     category['name'] = category.name_en;
    //-     let filter_option_by_category = this.filter_options.filter((item) => {
    //-       return (item.category_id == category.id && avaliable_filter_option_ids.indexOf(''+item.filter_id) != -1);
    //-     }).map(fo => { 
    //-       return {text: fo.option_en, value: fo.filter_option_id}
    //-     });
    //-     if(filter_option_by_category && filter_option_by_category.length){
    //-       category['filters'] = filter_option_by_category;
    //-     }
    //-   });
    //-   filter_categories = avaliable_filter_category.filter(category => {return category['filters'].length > 0});
    //-   console.log('filter_categories', filter_categories);
    //-   return filter_categories;
    //- },

    viewable_product_search_results(){
      if( this.product_search_total === 0 ) return [];
      let results = this.refined_search_results;
      return results.slice(this.from-1, this.through);
    }
  },

  async created(){
    const refineFilterOptionsLs = VueOfflineStorage.get('refine_filter_options')
    const refineModelsLs = VueOfflineStorage.get('refine_models')
    const refineBrandsLs = VueOfflineStorage.get('refine_brands')
    const refineCategoriesLs = VueOfflineStorage.get('refine_categories')
    const searchItemPerPageValue = Number(localStorage.getItem('search_result_itemperpage'))
    const searchPageNumber = Number(localStorage.getItem('search_page_no'))
    // debugger
    if(searchPageNumber){
      this.current_page = searchPageNumber
    }
    if(searchItemPerPageValue) {
      this.per_page = searchItemPerPageValue
    }
    if (refineFilterOptionsLs && refineFilterOptionsLs.length) {
      this.refine_filter_options = refineFilterOptionsLs;
    }
    if (refineModelsLs && refineModelsLs.length) {
      this.refine_models = refineModelsLs
    }
    if (refineBrandsLs && refineBrandsLs.length) {
      this.refine_brands = refineBrandsLs;
    }
    if (refineCategoriesLs && refineCategoriesLs.length) {
      this.refine_categories = refineCategoriesLs;
    }
    if(this.product_search_results.length == 0){
      if(this.$route.query['searchterm']){
        if(this.$route.query['searchterm']=="new_arrivals"){
          await this.newArrivalProductSearch(this.$t('landing.label.new_arrivals'));
          this.refine();
        }else{
          await this.searchProducts({product_search_term: this.$route.query['searchterm']});
          this.refine();
        }
      }else{
        this.clearSearch();
      }
    }else{
      this.refine_categories = JSON.parse(JSON.stringify(this.category_ids_in_scope()));
      this.header_search_term = JSON.parse(JSON.stringify(this.product_search_term));;
      this.search_model = JSON.parse(JSON.stringify(this.product_search_model));;
      this.brand_ids = JSON.parse(JSON.stringify(this.selected_brand_ids));
      this.category_ids = JSON.parse(JSON.stringify(this.selected_category_ids));
      this.refine();
    }
  },
  
  watch:{
    async $route(to, from) {
      if(to && from && to.query.search_term !== from.query.search_term){
        await this.searchProducts({product_search_term: to.query.search_term});
        this.refine();
      }
    },
    refine_filter_options(now, then){
      VueOfflineStorage.set('refine_filter_options', now)
      this.refine();
    },
    refine_models(now){
      VueOfflineStorage.set('refine_models', now)
    },
    refine_brands(now) {
      VueOfflineStorage.set('refine_brands', now)
    },
    refine_categories(now) {
      VueOfflineStorage.set('refine_categories', now)
    },
    product_search_results(now, then){
      this.clearRefinements();
      this.refine_categories = JSON.parse(JSON.stringify(this.category_ids_in_scope()));
      this.header_search_term = JSON.parse(JSON.stringify(this.product_search_term));;
      this.search_model = JSON.parse(JSON.stringify(this.product_search_model));;
      this.brand_ids = JSON.parse(JSON.stringify(this.selected_brand_ids));
      this.category_ids = JSON.parse(JSON.stringify(this.selected_category_ids));
    },
    product_search_model(now, then){
      this.search_model = now;
    },
    product_search_term(now, then){
      console.log('product_search_term');
      this.header_search_term = now;
    },
    selected_brand_ids(now, then){
      this.brand_ids = now;
    },
    selected_category_ids(now, then){
      this.category_ids = now;
    },
    category_ids() {
      this.calculatedAccessories = null;
      this.calculatedParts = null;
    }
  },

  methods:{
    ...mapActions( useProductSearchStore, ['removeCriterion','removeAllCriterion','searchProducts','refreshProductSearch', 'newArrivalProductSearch']),
    ...mapActions( useDbStore, ['brandFor','categoryFor','equipmentFor','subcategoriesOf']),
    category_ids_in_scope(){
      const refineCategoriesLs = VueOfflineStorage.get('refine_categories')
      if (refineCategoriesLs && refineCategoriesLs.length) {
        return refineCategoriesLs;
      }
      let cids = [];
      this.product_search_results.forEach(p=>{
        if(!cids.includes(p.category_id)) cids.push(p.category_id);
      });
      return cids;
    },

    sortRanks(data) {
      const recordsWithNoRank = data.filter(item => !item.rank_us)
      const recordsWithRank = data.filter(item => item.rank_us)
      return [
        ...recordsWithRank.sort((a, b) => (a.rank_us - b.rank_us)),
        ...recordsWithNoRank.sort((a, b) => a.name_en.localeCompare(b.name_en))
      ]
    },
    isCategorySearch() {
      if (this.header_search_term) {
        return false;
      }
      if (this.category_ids && this.category_ids.length) {
        return true
      }
      return false  
    },
    calculateCheckedStatus(categoriesTreeData) {
      categoriesTreeData.forEach(item => {
        item.checkedInfo = this.getCheckedInfo(item)
        if (item.nodes && item.nodes.length) {
          this.calculateCheckedStatus(item.nodes)
        }
      })
      return categoriesTreeData;
    },
    clearSearch(){
      console.log('@@@@@@@@@@@@ rat @@@@@@@@@')
      this.brand_ids = [];
      this.refine_brands = [];
      this.refine_models = [];
      this.refine_categories = [];
      this.search_model = "";
      this.header_search_term = "";
      this.category_ids = [];
      this.refine_filter_options = [];
      this.refreshSearch();
      this.isCategoryMoreRefine = false;
    },

    changeParePage(e){
      //- console.log(e);
      this.current_page = 1;
      this.per_page = e;
      localStorage.setItem('search_result_itemperpage', e)
    },
    getOptionCategories(value){
      let filter = {
        name: '',
        value: ''
      };
      this.filter_option_categories.forEach(item => {
        item.filters.forEach(filterItem => {
          let option = filterItem.options.find(option => option.value == value);
          if(option){
            //- console.log('***', filterItem, value, option);
            filter['name'] = filterItem.name;
            filter['value'] = option.text;
          }
        });
      });
      return filter;
    },
    /** Tree-building function for the Tree component */
    buildTree( category ){
      let node = {
        text: this.translateText(category),
        id: category.id,
        nodes:[],
        visible: true,
      };

      let children = this.categories.filter( c => { 
        return c.parent_id == category.id;
      });

      children.forEach( child => {
        let childNode = this.buildTree( child );
        node.nodes.push(childNode);
      });

      return node;
    },
    removeOptionCategory(id){
      let index = this.refine_filter_options.indexOf(id);
      this.refine_filter_options.splice(index, 1);
      this.refine();
    },
    removeCategory(id){
      let index = this.refine_categories.indexOf(id);
      this.refine_categories.splice(index, 1);
      this.refine();
    },
    removeModel(id){
      let index = this.refine_models.indexOf(id);
      this.refine_models.splice(index, 1);
      this.refine();
    },
    removeBrand(id){
      let index = this.refine_brands.indexOf(id);
      this.refine_brands.splice(index, 1);
      this.refine();
    },
    onRemoveSubCategory(c, isParent, isSuperParent=false){
      if(isParent){
        // this.isCategoryMoreRefine = true;
        let category = {
          id: c.id,
          nodes: []
        }
        if(category.id == ACCESSORY_CATEGORY_ID){
          category.nodes = this.accessories;
        }else if(category.id == PART_CATEGORY_ID){
          category.nodes = this.parts;
        }else{
          category = c;
        }
        let pcIndex = this.refine_categories.indexOf(category.id);
        if(pcIndex != -1){
          this.refine_categories.splice(pcIndex, 1);
        }
        category.nodes.forEach(item => {
          let ind = this.refine_categories.indexOf(item.id);
          if (ind !== -1) {
            this.refine_categories.splice(ind, 1);
          }
          if(item['nodes'] && item['nodes'].length){
            item.nodes.forEach(subItem => {
              let indx = this.refine_categories.indexOf(subItem.id);
              if (indx !== -1) {
                this.refine_categories.splice(indx, 1);
              }
              if(isSuperParent){
                subItem.nodes.forEach(subChildItem => {
                  let index = this.refine_categories.indexOf(subChildItem.id);
                  if (index !== -1) {
                    this.refine_categories.splice(index, 1);
                  }
                });
              }
            })
          }
        });
      }
      //- console.log(this.refine_categories)
      this.refine();
    },
    onCategoryChange(c, isParent, isSuperParent=false){
      //- console.log(isParent, isSuperParent);
      this.isCategoryMoreRefine = true;
      if(isParent){
        let category = {
          id: c.id,
          nodes: []
        }
        if(category.id == ACCESSORY_CATEGORY_ID){
          category.nodes = this.accessories;
        }else if(category.id == PART_CATEGORY_ID){
          category.nodes = this.parts;
        }else{
          category = c;
        }
        let index = this.refine_categories.indexOf(category.id);
        if(index == -1){
          category.nodes.forEach(item => {
            let ind = this.refine_categories.indexOf(item.id);
            if (ind !== -1) {
              this.refine_categories.splice(ind, 1);
            }
            if(item['nodes'] && item['nodes'].length){
              item.nodes.forEach(subItem => {
                let indx = this.refine_categories.indexOf(subItem.id);
                if (indx !== -1) {
                  this.refine_categories.splice(indx, 1);
                }
                if(isSuperParent){
                  subItem.nodes.forEach(subChildItem => {
                    let index = this.refine_categories.indexOf(subChildItem.id);
                    if (index !== -1) {
                      this.refine_categories.splice(index, 1);
                    }
                  });
                }
              })
            }
          });
        }else{
          category.nodes.forEach(item => {
            let ind = this.refine_categories.indexOf(item.id);
            if (ind == -1) {
              this.refine_categories.push(item.id);
            }
            if(item['nodes'] && item['nodes'].length){
              item.nodes.forEach(subItem => {
                let indx = this.refine_categories.indexOf(subItem.id);
                if (indx == -1) {
                  this.refine_categories.push(subItem.id);
                }
                if(isSuperParent){
                  subItem.nodes.forEach(subChildItem => {
                    let index = this.refine_categories.indexOf(subChildItem.id);
                    if (index == -1) {
                      this.refine_categories.push(subChildItem.id);
                    }
                  });
                }
              })
            }
          });
        }
      }
      //- console.log(this.refine_categories)
      this.refine();
    },

    categoryRefinment(){
      this.refine_categories = this.category_ids_in_scope();
      this.refine();
    },

    page(p){
      window.requestAnimationFrame(() => {
        window.scrollTo(0,0);
      })
      this.current_page = p;
      localStorage.setItem('search_page_no', p)
    },

    clearRefinements(){
      //Any time a new search is detected, all refinement vars must be cleared...
      this.refine_term = '';
      this.refine_brands = [];
      this.refine_categories = this.category_ids_in_scope();
      this.refine_models = [];
      this.refine_filter_options = [];
      this.isCategoryMoreRefine = false;
    },
    lifeCycleFor(id) {
      return this.lifecycle_filters.find(item => item.id === id)
    },
    refine(){
      this.refined_search_results = this.product_search_results;
      if(this.refine_term){
        //- console.debug('refining by term...'); 
        this.refined_search_results = this.refined_search_results.filter(p=>{
          return ( p.sku && p.sku.toLowerCase().includes(this.refine_term.toLowerCase()) )
            || ( p.description_en && p.description_en.toLowerCase().includes(this.refine_term.toLowerCase()) )
            || ( p.name_en && p.name_en.toLowerCase().includes(this.refine_term.toLowerCase()) )
            || ( p.oem && p.oem.toLowerCase().includes(this.refine_term.toLowerCase()));
        });
      }
      if(this.refine_brands?.length > 0){
        console.debug('refining by oem...'); 
        this.refined_search_results = this.refined_search_results.filter(p=> {
          const isIntersection = this.refine_brands.some(element => p.compatibilityBrandIds.includes(element));
          return isIntersection;
        });
      }
      //- if(this.refine_categories.length > 0){
      //-   console.debug('refining by category...'); 
      this.refined_search_results = this.refined_search_results.filter(p=>{ 
        return this.refine_categories.includes( p.category_id );
      });
      //- }
      if(this.refine_models.length > 0){
        //- console.debug('refining by model...'); 
        this.refined_search_results = this.refined_search_results.filter(p=>{
          let match = false;
          if(p.models){
            p.models.split('|').forEach(model=>{
              if(this.refine_models.includes(model)){
                match = true; return;
              }
            });
          }
          return match;
        });
      }
      console.log(this.refine_filter_options)
      if(this.refine_filter_options.filter((item) => typeof item === 'number').length > 0){
        this.refined_search_results = this.refined_search_results.filter(p => {
          if (p.filter_option_ids) {
            const productFilterPossible = p.filter_option_ids.split('|');
            const checkIn = this.refine_filter_options.filter(item => !`${item}`.includes('lifecycle'))
            if (checkIn.every(item => productFilterPossible.includes(`${item}`))) {
              return true
            }
          }
          return false;
        });
      }
      // 
      const filterOptionsForLifecycles = this.getSelectedLifecycleFilterOption()
      console.log(filterOptionsForLifecycles)
      if (filterOptionsForLifecycles.length > 0) {
        console.log(this.refined_search_results.length)
        this.refined_search_results = this.refined_search_results.filter(p => {
          const foundMatch = filterOptionsForLifecycles.find((item) => {
            if (p.lifecycle_id === item.lifecycleId) {
              return true
            }
          })
          return foundMatch ? true : false;
        })
        console.log(this.refined_search_results.length)
      }
      this.refined_search_results = this.refined_search_results.sort((a, b)=>{
        //- console.log(a.stock_usa, b.stock_usa);
        if(a.stock_usa > b.stock_usa) return -1;
        else if(a.stock_usa < b.stock_usa) return 1;
        else return 0;
      });
      //- console.log(this.refined_search_results);
      setTimeout(()=>{
        const filterList = document.getElementById(`selected-filter-list`);
        const productList = document.getElementById(`product-list`);
        if(productList) productList.style.marginTop = (Number(filterList?.offsetHeight) + 34) + "px";
      }, 1000);
      this.search_modify_alert = true;
      setTimeout(()=>{
        this.search_modify_alert = false;
      }, 3000);
    },

    async refreshSearch(){
      this.isCategoryMoreRefine = false;
      this.removeAllCriterion();
      //- useProductSearchStore().$reset();
      await this.refreshProductSearch();
      this.refine();
    },
    
    adjustShowingBrands(type){
      if(type == 'all'){
        this.showing_brands = this.brands_in_scope.length;
        if(this.showing_brands < 5) this.showing_brands = 5;
      }else if(type == 10){
        this.showing_brands += type;
        if(this.showing_brands > this.brands_in_scope.length) this.showing_brands = this.brands_in_scope.length;
        if(this.showing_brands < 5) this.showing_brands = 5;
      }else{
        this.showing_brands = 5;
      }
    },

    //- adjustShowingCategories(incr){

    //-   this.showing_categories += incr;
    //-   if(this.showing_categories > this.refine_categories.length) this.showing_categories = this.refine_categories.length;
    //-   if(this.showing_categories < 5) this.showing_categories = 5;
    //- },

    adjustShowingModels(type){
      if(type == 'all'){
        this.showing_models = this.models_in_results.length;
        if(this.showing_models < 5) this.showing_models = 5;
      }else if(type == 10){
        this.showing_models += type;
        if(this.showing_models > this.models_in_results.length) this.showing_models = this.models_in_results.length;
        if(this.showing_models < 5) this.showing_models = 5;
      }else{
        this.showing_models = 5;
      }
      console.log(this.showing_models);
    },

    adjustShowingAccessories(type){
      if(type == 'all'){
        this.showing_accessories = this.accessories.length;
        if(this.showing_accessories < 5) this.showing_accessories = 5;
      }else if(type == 5){
        this.showing_accessories += type;
        if(this.showing_accessories > this.accessories.length) this.showing_accessories = this.accessories.length;
        if(this.showing_accessories < 5) this.showing_accessories = 5;
      }else{
        this.showing_accessories = 5;
      }
    },

    adjustShowingParts(type){
      if(type == 'all'){
        this.showing_parts = this.parts.length;
        if(this.showing_parts < 5) this.showing_parts = 5;
      }else if(type == 5){
        this.showing_parts += type;
        if(this.showing_parts > this.parts.length) this.showing_parts = this.parts.length;
        if(this.showing_parts < 5) this.showing_parts = 5;
      }else{
        this.showing_parts = 5;
      }
    },

    getCategoryType(id){
      let category = this.categories.find(c=>c.id==id);
      let parent_id;
      if(category){
        parent_id = category['parent_id'];
        while(parent_id != 1 && parent_id != 2 && category){ 
          category = this.categories.find(c=> c.id == parent_id);
          if(category){ 
            parent_id = category['parent_id'];
          }
        }
        if(parent_id == 1){
          return this.$t("global.menu.accessories");
        }else if(parent_id == 2){
          return this.$t("global.menu.parts");
        }else{
          return "";
        }
      }else{
        return "";
      }
    },

    getProductCountByCategory(category){
      let category_ids = [];
      let results = [];
      category_ids.push(category.id);
      category.nodes.forEach(item => {
        category_ids.push(item.id);
        if(item['nodes'] && item['nodes'].length){
          item['nodes'].forEach(subItem => {
            category_ids.push(subItem.id);
            if(subItem['nodes'] && subItem['nodes'].length){
              subItem['nodes'].forEach(subChildItem => {
                category_ids.push(subChildItem.id);
              });
            }
          });
        }
      });
      //- console.log(category_ids);
      //- return category_ids;
      if(category_ids.length > 0){
        results = this.product_search_results.filter(p=>{ 
          return category_ids.includes( p.category_id );
        });
      }
      return results.length;
    },
    getSelectedLifecycleFilterOption() {
      return this.refine_filter_options?.filter((item) => (typeof item === 'string' && item.includes('lifecycle'))).map((item) => {
        const [, catId, lifecycleId] = item.split('-')
        return {
          catId: Number(catId),
          lifecycleId: Number(lifecycleId)
        }
      }) || []
    },
    getCheckedInfo(c){
      let category = {
        id: c.id,
        nodes: []
      };
      let category_ids = [];
      let results = [];
      if(category.id == ACCESSORY_CATEGORY_ID){
        category.nodes = this.accessories;
      }else if(category.id == PART_CATEGORY_ID){
        category.nodes = this.parts;
      }else{
        category = c;
      }
      //- category_ids.push(category.id);
      category.nodes.forEach(item => {
        category_ids.push(item.id);
        if(item['nodes'] && item['nodes'].length){
          item['nodes'].forEach(subItem => {
            category_ids.push(subItem.id);
            if(subItem['nodes'] && subItem['nodes'].length){
              subItem['nodes'].forEach(subChildItem => {
                category_ids.push(subChildItem.id);
              });
            }
          });
        }
      });
      if(category_ids.length > 0){
        results = category_ids.filter(p => { 
          return this.refine_categories.includes(p);
        });
        this.$nextTick(() => {
          //- let r = category_ids.filter(p => { 
          //-   return this.refine_categories.includes(p);
          //- });
          if(category_ids.length == results.length && !this.refine_categories.includes(category.id)){
            this.refine_categories.push(category.id);
          }else if(results.length == 0){
            const index = this.refine_categories.indexOf(category.id);
            if (index > -1) { // only splice array when item is found
              this.refine_categories.splice(index, 1); // 2nd parameter means remove one item only
            }
          }
        });
      }
      
      let temp =  {
        total: category_ids.length,
        checked: results.length,
        selfChecked: this.refine_categories.includes(category.id)
      };
      //- console.log(temp);
      return temp;
    },
    getBrandCountById(brandId){
      let result = this.product_search_results.filter(p=>{ 
        return (p.oem_brand_id == brandId);
      });
      return result.length;
    },
    async executeProductSearch(search_term){
      let parms = {}
      if(search_term){
        parms['product_search_term'] = search_term;
      }
      //- useProductSearchStore().$reset(); // when executed from here, always starts a totally fresh search.
      await this.searchProducts(parms);
      // Trigger product selection when only one result.
      if(this.product_search_total === 1 && this.product_search_results[0].id){
        //- if(this.$route.name !== 'Product') this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        if(this.$route.name !== 'Product'){ 
          this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        }else if(this.$route.params && this.$route.params['product_id'] && (this.$route.params['product_id'] != this.product_search_results[0].id)){
          this.$router.push({ name: 'Product', params: {product_id:  this.product_search_results[0].id} });
        }
      } else if (this.product_search_total > 1) {
        if(this.$route.name !== 'Search'){
          this.$router.push({ name: 'Search', params: {search_term:  this.search_term} });
        } else {
          if(this.product_search_total===0){
            useBaseStore().$patch({ message: this.$t(`global.verbiage.product_not_found`) });
          }
        }
      }
    }
  },

}
</script>
<style lang="scss">
.search-brand-filter-wrap{
  input[type=checkbox]{
    top: .1rem;
  }
  .custom-control-label{
    &::before {
      top: .1rem;
    }
    &::after {
      top: .1rem;
    }
  }
  .add-popular{
    margin-bottom: 5px;
    margin-top: 5px;
    label{
      &.popular{
        font-size: 15px;
        font-weight: 600;
        display: block;
        position: relative;
        left: -25px !important;
      }
    }
    input[type=checkbox]{
      top: 1.95rem;
    }
    .custom-control-label{
      &::before {
        top: 1.95rem;
      }
      &::after {
        top: 1.95rem;
      }
    }
  }
  .add-non-popular{
    margin-bottom: 5px;
    margin-top: 5px;
    label{
      &.non-popular{
        border-top: 1px solid #b5b5b5;
        display: block;
        position: relative;
        left: -25px !important;
      }
    }
    input[type=checkbox]{
      top: 0.65rem;
    }
    .custom-control-label{
      width: 100%;
      &::before {
        top: 0.65rem;
      }
      &::after {
        top: 0.65rem;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.alert-success {
  color: #155724;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
</style>
<style lang="scss">
#product-search-result{
  position: absolute;
  margin-top: 131px;
  width: 100%;
  @media (max-width: 767px){
    margin-top: 142px;
  }
  .b-pagination-pills .page-item .page-link {
    border-radius: 0.1rem !important;
    margin-left: 0.5rem;
  }
  .search-heading{
    padding: .4rem;
    background: #F2F3F4;
    padding-left: 1rem;
    font-size: 14px;
    font-weight: 500; 
    @media (min-width: 992px){
      position: fixed;
      top: 131px;
      width: 100vw;
      left: 0;
      z-index: 6;
    }
  }
  
  .search-body{
    .sidebar-filter{
      .b-sidebar-header{
        padding-bottom: 0px;
      }
      .selected-filter-list{
        padding: 1.05rem;
        border-bottom: 1px solid #ccc;
        .filter-heading {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 0.3rem;
        }
        .filter-item{
          border-radius: 5px;
          margin: .15rem;
          padding: 0.3rem 0.5rem 0.5rem;
          background: #38bcd8;
          color: White;
          font-size: 10px;
          display: inline-block;
          .value{
            font-weight: 600;
          }
          .icon-close{
            padding-left: .3rem;
            font-weight: 600;
            font-size:14px;
            cursor: pointer;
          }
          &.clear{
            cursor: pointer;
            font-size: 10px;
            color: #272727;
            background: white;
            .icon-close{
              padding-left: 0;
            }
          }
        }
      }
    }
    .left-body{
      .filter-block{
        .search-filter{
          padding: .5rem 1.5rem;
          background: #38BCD9;
          .search-title{
            color: white;
            padding-bottom: .2rem;
            font-size: 18px;
            font-weight: 600;
          }
          .search-input{
            input{
              background: #38bcd8;
              color: white;
              border-color: white;
              font-size: 14px;
              &::placeholder {
                color: white;
              }
            }
            .input-group-text{
              background: white;
              border-bottom-left-radius: 0px;
              border-top-left-radius: 0px;
              color: #38bcd8;
              border-color: white;
            }
          }
          @media (max-width: 992px){
            background: #f8f9fa;
            .search-title{
              color: black;
              padding-bottom: .5rem;
              font-size: 16px;
              font-weight: 600;
            }
            .search-input{
              input{
                background: white;
                color: black;
                border-color: #38bcd8;
                font-size: 14px;
                &::placeholder {
                  color: black;
                }
              }
              .input-group-text{
                background: white;
                border-bottom-left-radius: 0px;
                border-top-left-radius: 0px;
                border-color: #38bcd8;
                border-left: 0;
                color: #38bcd8;
              }
            }
          }
          @media (min-width: 992px){
            position: fixed;
            top: 165px;
            width: 25vw;
            left: 0;
            z-index: 6;
          }
        }
      }
      .filter-list-block{
        background: #DFEFF2;
        padding: 1rem;
        border: 3px solid #38bcd8;
        @media (max-width: 992px){
          border: 0;
          background: #f8f9fa;
          margin-bottom: 3rem;
        }
        @media (min-width: 992px){
          margin-top: 114px;
          position: fixed;
          width: 25vw;
          overflow-x: scroll;
          height: calc(100vh - 245px);
          z-index: 1;
        }
        .accordion{
          margin-bottom: .5rem;
          .card{
            .card-header{
              .accordion-title{
                padding: 0.8rem 1rem;
                font-size: 14px;
                font-weight: 600;
                color: #38bcd8;
                .accordion-arrow{
                  position: absolute;
                  right: 10px;
                  top: 10px;
                }
                &.collapsed{
                  .accordion-arrow{
                    transform: rotate(180deg);
                  }
                }
              }
            }
            .card-body{
              font-size: 0.75rem;
              font-weight: 500;
              padding: 0.6rem 1rem;
              .custom-control-label{
                font-weight: 500;
                font-size: 0.75rem;
                span{
                  font-weight: 500;
                  font-size: 0.75rem;
                }
              }
              .pagination{
                color: #7F7F7F;
                a{
                  color: #7F7F7F;
                }
              }
              .top-search-group{
                margin-bottom: .7rem;
                .input-search{
                  position: relative;
                  span{
                    font-size: 17px;
                    position: absolute;
                    top: 5px;
                    left: 10px;
                  }
                  input{
                    font-size: 14px;
                    padding-left: 35px;
                  }
                  @media (max-width: 992px){
                    input{
                      font-size: 12px;
                      padding: 17px 15px 17px 35px;
                    }
                  }
                }
              }
            }
          }
          &.category-block{
            .parent-category{
              .parent-category-label{
                .custom-control-label{
                  color: #7F7F7F;
                  font-size: .95rem;
                  font-weight: 500;
                  margin-bottom: 0.5rem;
                  span {
                    color: #7F7F7F;
                    font-size: .95rem;
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                  }
                }
                span {
                  color: #7F7F7F;
                  font-size: .95rem;
                  font-weight: 500;
                  margin-bottom: 0.5rem;
                }
                svg{
                  font-size: 15px;
                }
              }
              .remove-category{
                svg{
                  font-size: .9rem;
                }
              }
              .category-header{
                position: relative;
                .mini-accordion{
                  position: absolute;
                  top: 0px;
                  left: -2px;
                  font-size: 14px;
                }
                .collapsed{
                  transform: rotate(-90deg) !important;
                }
              }
              .sub-category{
                padding-left: 12px;
                margin-bottom: .5rem !important;
              
                .custom-control-label{
                  font-weight: 400;
                }
                .sub-category-item{
                  position: relative;
                  .mini-accordion{
                    position: absolute;
                    top: 0px;
                    left: -2px;
                    font-size: 14px;
                    z-index: 9;
                    &.collapsed{
                      transform: rotate(-90deg) !important;
                    }
                  }
                  .sub-category{
                    padding-left: 16px;
                  }
                }
              }
            }
            .additional-filter-options{
              .sub-header{
                font-size: .9rem;
                font-weight: 500;
                margin-bottom: .2rem;
                @media (max-width: 992px){
                  font-size: .8rem;
                }
              }
              .sub-item-list{
                .custom-control-label{
                  font-weight: 400;
                  font-size: 0.75rem;
                }
              }
            }
          }
        }
      }
      .mob-filter-footer{
        padding: 0.7rem;
        position: absolute;
        bottom: 0;
        text-align: center;
        background: white;
        z-index: 9;
        width: 100%;
      }
    }
    .right-body{
      .search-result-block{
        .selected-filter-list{
          padding: 0.2rem 0.5rem;
          border-bottom: 1px solid #ccc;
          .filter-item{
            border-radius: 5px;
            margin: 0.2rem;
            padding: 0.2rem 0.5rem 0.3rem 0.5rem;
            background: #38bcd8;
            color: White;
            font-size: 13px;
            display: inline-block;
            .value{
              font-weight: 600;
            }
            .icon-close{
              padding-left: .3rem;
              font-weight: 600;
              font-size:16px;
              cursor: pointer;
            }
            &.clear{
              cursor: pointer;
              font-size: 14px;
              color: #272727;
              background: white;
              .icon-close{
                padding-left: 0;
              }
            }
          }
          @media (min-width: 992px){
            max-height: 90px;
            min-height: 90px;
            overflow-x: scroll;
            position: fixed;
            top: 165px;
            width: 75vw;
            right: 0;
            z-index: 6;
            background: white;
          }
        }
        .mobile-filter{
          text-align: center;
          padding: .5rem;
          border-bottom: 1px solid #ccc;
          .mobile-filter-wrap{
            border: 1px solid #36c6cc;
            display: inline-block;
            padding: 5px 10px 10px 10px;
            border-radius: 5px;
            line-height: 1;
            .filter-icon{
              padding-top: 5px;
              display: inline-block;
            }
            .filter-item{
              margin: 0rem 0.15rem;
              font-size: 8px;
              display: inline-block;
            }
          }
        }
        .product-list{
          padding: 1rem;
          @media (max-width: 540px){
            padding: .6rem;
          }
          @media (min-width: 992px){
            margin-top: 30px;
          }
        }
      }
    }
  }
}
.disabledModel {
  pointer-events: none;
  opacity: 0.50;
}

#imgs-carousel{
  text-shadow: 1px 1px 2px #333;
  max-height: 200px;
  overflow: scroll;
}

.pagination-content {
  padding: 15px;
  margin-bottom: 15px;
  a {
    text-decoration: none;
    color: black;
  }
  @media (max-width: 540px){
    padding: 0px 15px !important;
    border: none !important;
    margin: 15px 0;
  }
  .item-per-page{
    display: flex;
    align-items: center;
    label{
      font-size: 12px;
      font-weight: 500;
      margin-right: .5rem;
    }
    select{
      width: 70px;
    }
  }
  .pagination-block{
    display: flex;
    align-items: center;
    justify-content: center;
    .pagination{
      margin-bottom: 0;
    }
  }
  .page-info{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #7F7F7F;
    font-size: 12px;
  }
}
.left-subheader{
  background: #e6e6e6;
  font-size: 0.9em;
  font-weight: bold;
  width: 100%;
  padding-left: 0.3em;
}
.product-display-header {
  border-top: 2px solid #2dc1c1 !important;
  border-bottom: 2px solid #2dc1c1 !important;
  .breadcrumb-trail {
    background: #e6e6e6;
    padding: 4px;
    border-radius: 4px;
  }
}
.show-lbl{
  font-size: .8em;
}
</style>